.container_group{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.container_div{
    margin-right: 15;
    font-family: sans-serif;
    font-size: 15;
    font-weight: bold;
}

.container_select{
    padding: 5;
    border-radius: 5;
    border: 1px solid #ccc;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 60vh;
  }