.leases {
    background-color:  lightgray;
    margin-top: 30px;
    width: 80%;
    /* height: 5rem; */
    padding: 5px;
    cursor: pointer;
}

.leases:hover {
    margin-top: 25px;
    -webkit-box-shadow: 15px 14px 6px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 15px 14px 6px -5px rgba(0,0,0,0.75);
    box-shadow: 15px 14px 6px -5px rgba(0,0,0,0.75);
}

.flex-screen {
    /* display: flex; */
    background-color: white;
    margin: auto;
    width: 80%;
    height: auto;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    /* border: 3px solid #73AD21; */
    /* margin-top: 315px; */
    /* padding-bottom: 30px; */
    text-align: center;
}

.text-info {
    /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
    font-weight: 600;
    color: #4a5568 !important; 
    font-size: 1.25rem;
    letter-spacing: .025em;
    -webkit-font-smoothing: antialiased;
}

#file {
    display: none;
}

.uploader {
    min-width: 300px;
}
