.basic_outline {
    display: flex;
    flex-direction: column;
}

.outline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
}

.content {
    margin-right: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
}

.input_content {
    padding: 5;
    font-size: 0.75rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.green_button {
    font-family: "Montserrat-Semibold", sans-serif;
    background-color: #22B378;
    color: #FFF;
    font-size: 0.85rem;
  }

  .red_button {
    font-family: "Montserrat-Semibold", sans-serif;
    background-color: #F76831;
    color: #FFF;
    font-size: 0.7rem;
  }