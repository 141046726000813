.contin {
  display: flex;
  height: 100vh;
  transition: all 0.3s ease;
  width: 100%;
}

.left-panel {
  /* flex: 1; */
  /* padding: 20px; */
  border-right: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.3s ease;
  width: 250%;
}

.right-panel {
  /* flex: 0; */
  /* padding: 20px; */
  overflow-y: auto;
  transition: all 0.3s ease;
  height: 68vh;
}

.contin.split .left-panel {
  /* flex: 1; */
  width: 42%;
}

.contin.split .right-panel {
  /* flex: 2; */
  width: 120%;
  height: 100vh;
}

.split{
  width: 83%;
}

.close-btn {
  /* position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px; */
  background-color: #233067;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.close-btn:hover {
  background-color: #d32f2f;
}