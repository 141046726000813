table.report-table td {
    background-color: white;
}

table.report-table th:nth-child(1),
table.report-table td:nth-child(1),
table.report-table th:nth-child(2),
table.report-table td:nth-child(2),
table.report-table th:nth-child(3),
table.report-table td:nth-child(3),
table.report-table th:nth-child(4),
table.report-table td:nth-child(4)
{
  position: sticky;
  left: 3px;
  /* border: 0.5px solid #bfbfbf; */
  border:  1px solid #d9d9d9;
}

table.report-table th:nth-child(1),
table.report-table th:nth-child(2),
table.report-table th:nth-child(3),
table.report-table th:nth-child(4)
{
  z-index: 5;
}


table.report-table th:nth-child(2),
table.report-table td:nth-child(2)
{
  left: 80px;
}

table.report-table th:nth-child(3),
table.report-table td:nth-child(3)
{
  left: 290px;
}

table.report-table th:nth-child(4),
table.report-table td:nth-child(4)
{
  left: 363px;
}
