.calc {
    width: 100% !important;
    text-align: left;
}

label {
    font-weight: bolder;
    font-size: 18px;
}


h4 {
    color: orange;
    width: 300px;
    font-weight: 600;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: left;
}

.calc-display {
    color: #233067;
}

.recom-box {
    border: #233067 2px dashed;
    padding: 10px;
    margin: 10px;
    background-color: lightblue;
    transition: 2s;
}

.calc-table {
    border: #233067 2px dashed;
    background-color: lightblue;
    transition: 2s;
    /* border-collapse: collapse dashed; */
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table.calc-table>tbody>tr>td {
    text-align: center;
    padding: 8px;
}

table.calc-table>thead>tr>th {
    text-align: center;
    padding: 8px;
}