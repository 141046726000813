.leases {
  background-color: lightgray;
  margin-top: 5px !important;
  width: 95% !important;
  /* height: 5rem; */
  padding: 5px;
  cursor: pointer;
}

.leases:hover {
  border: 1px solid green;
  margin-top: 8px !important;
  -webkit-box-shadow: 13px 6px 30px -5px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 13px 6px 30px -5px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 13px 6px 30px -5px rgba(0, 0, 0, 0.75) !important;
}

.icon-btn {
  cursor: pointer;
}


table.BootstrapTable th {
  position: sticky;
  top: 0 !important;
  z-index: 1;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #d9d9d9;
  color: black;
  /* border: 1px solid #000 !important; */
}

table.BootstrapTable {
  height: 10px !important;
  width: 100% !important;
  border-collapse: separate;
  padding-bottom: 0px !important;
}

table.BootstrapTable thead.BootstrapTable th {
  vertical-align: middle !important;
  border:  1px solid #000 !important;
}

table.BootstrapTable td {
  vertical-align: top !important;
  white-space: nowrap !important;
  color: black;
}

table.acc-table th:nth-child(1),
table.acc-table td:nth-child(1),
table.acc-table th:nth-child(2),
table.acc-table td:nth-child(2),
table.acc-table th:nth-child(3),
table.acc-table td:nth-child(3)
{
  position: sticky;
  left: 3px;
  /* border: 0.5px solid #bfbfbf; */
  border:  1px solid #d9d9d9;
  /* background-color: red; */
}

table.acc-table th:nth-child(1),
table.acc-table th:nth-child(2),
table.acc-table th:nth-child(3)
{
  z-index: 5;
}


table.acc-table th:nth-child(2),
table.acc-table td:nth-child(2)
{
  left: 200px;
}

table.acc-table th:nth-child(3),
table.acc-table td:nth-child(3)
{
  left: 290px;
}

table.BootstrapTable p {
  margin-bottom: 0rem !important;
}

table.BootstrapTable td,
table.BootstrapTable th {
  padding: 2px !important;
}


#lease-table {
  margin-left: 0px !important;
}

.active-lease {
  border: 2px solid green;
}

.lease-card {
  width: 110% !important;
}

.flex-screen {
  /* display: flex; */
  background-color: white;
  margin: auto;
  width: 80%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  /* border: 3px solid #73AD21; */
  /* margin-top: 315px; */
  /* padding-bottom: 30px; */
  text-align: center;
}

.text-info {
  /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  font-weight: 600;
  color: #4a5568 !important;
  font-size: 1.25rem;
  letter-spacing: .025em;
  -webkit-font-smoothing: antialiased;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spin {
animation-name: spin;
animation-duration: 4000ms;
animation-iteration-count: infinite;
animation-timing-function: linear;
}

.scroll-div {
  height: 77vh;
  margin-left: -12px !important;
  overflow: scroll;
}

@media only screen and (max-width: 767px) {

  #lease-table {
    margin-left: -40px !important;
    width: 112% !important;
  }

  
  table.acc-table th:nth-child(1),
  table.acc-table th:nth-child(2),
  table.acc-table th:nth-child(3)
  {
    position: sticky;
    border:  1px solid #d9d9d9 !important;
    top: 0 !important;
    left: auto !important;
  }
  
  table.acc-table td:nth-child(1),
  table.acc-table td:nth-child(2),
  table.acc-table td:nth-child(3)
  {
    position: unset !important;
    border:  1px solid #d9d9d9 !important;
  }




}

/* table.acc-table thead tr.customRowClass th:first-child,
table.acc-table tbody tr.customRowClass td:first-child {
  background: yellow;
  position:fixed !important; 
  top: auto;
  left: 0 !important;
}


table.acc-table thead tr.customRowClass th:nth-child(2),
table.acc-table tbody tr.customRowClass td:nth-child(2) {
  padding-left: 7em;
} */