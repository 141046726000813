  @media (min-width: 500px) {

    .cs-form {
      padding: 30px 42px 32px !important;
      /* border: 1px solid #CDCDCD!important; */
      border-radius: 8px !important;
    }
  }

  /* .cs-form {
    margin-top: 54px!important;
  } */

  @media (min-width: 1200px) {

    /* .cs-form {
      padding-top: 54px!important;
    } */
  }

  @media (min-width: 500px) {

    .cs-form__title {
      /* position: absolute!important; */
      /* top: -16px!important; */
      margin-bottom: 0 !important;
      font-size: 18px !important;
      /* margin-top: 54px!important; */

    }
  }

  @media (min-width: 1200px) {

    .cs-form__title {
      font-size: 20px !important;
      /* top: -18px!important; */
    }
  }

  .cs-form__title,
  .page2_head,
  .page4_head {
    color: #00000A !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 900 !important;
    line-height: 32px !important;
    font-size: 21px !important;
    background: #fff !important;
    padding: 0 12px !important;
    margin-left: -12px !important;
    margin-bottom: 32px !important;
  }

  #signup {
    display: block !important;
    margin-top: 0em !important;
  }

  .cs-form__group {
    margin-bottom: 24px !important;
  }

  .cs-form__label {
    color: #727272 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    margin-bottom: 12px !important;
  }

  .cs-form__field {
    position: relative !important;
    font: inherit !important;
  }

  .cs-form__control {
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
    min-width: 42px !important;
    height: 46px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    font-size: 16px !important;
    border: 1px solid #CDCDCD !important;
    border-radius: 4px !important;
    background-color: #FFFFFF !important;
    transition: border-color ease-in-out 0.15s !important;
    color: #202022 !important;
    padding: 0 16px !important;
  }

  /* .emailInput , .phoneInput {

      overflow: visible!important;
      font: inherit!important;
  }

  button,
  input {
    overflow: visible!important;
    font: inherit!important;
  } */

  /* #phone {
    width: 100% !important!important;
  } */

  @media (min-width: 500px) {

    .container {
      max-width: 500px !important;
      margin: auto !important;
    }
  }

  @media (min-width: 1200px) {

    .container {
      max-width: 630px !important;
      margin: auto !important;
    }
  }

  .checkbox {
    width: 38px !important;
    height: 18px !important;
    position: absolute !important;
  }

  .cs-checkbox__label {
    cursor: pointer !important;
    display: inline-block !important;
    color: #727272 !important;
    font-weight: normal !important;
  }


  .cs-form__title,
  .cs-form__label {
    text-align: left;
    /* font-weight: 700!important;  */
  }

  .cs-checkbox__value {
    vertical-align: middle !important;
    margin-left: 2.5rem !important;
    font: inherit !important;

  }

  .cs-form__btn {
    margin-top: 1rem !important;
    background-color: #233067 !important;
    border-width: 0 !important;
    border-radius: 4px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 20, 0.08) !important;
    transition: 0.3s !important;
    /* line-height: 52px!important; */
    height: 50px !important;
    width: 100% !important;
  }

  /* @media only screen and (min-width: 1024px) {
  .cs-form__btn {
    height: 56px!important;
    line-height: 56px!important;
  }
}
@media only screen and (max-width: 1023px) {
  .cs-form__btn {
    line-height: 52px!important;
    height: 52px!important;
    width: 100%!important;
  }
} */
  .cs-form__btn:hover,
  .btn1:hover,
  .btn2:hover,
  .pass_btn:hover {
    color: #ffffff !important;
    box-shadow: 0 3px 15px 0 rgba(90, 122, 190, 0.25) !important;
    opacity: .8 !important;
  }

  .cs-form__btn[disabled] {
    filter: grayscale(100%) !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }

  .cs-form__control.has-error {
    border: 2px solid #E74C3C !important;
  }

  .help-block {
    font-size: 1.1rem !important;
    z-index: 2 !important;
    position: absolute !important;
    z-index: 10 !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: .8rem !important;
    color: #E74C3C !important;
  }

  .email_info_addon {
    padding-top: 1rem !important;
    padding-bottom: 0 !important;
    color: #E74C3C !important;
  }

  .page2_head {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
  }

  @media only screen and (max-width: 1290px) {
    .page2_head {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 1rem !important;
      font-weight: 900 !important;
      line-height: 32px !important;
      font-size: 19px !important;
    }

    .page2_p,
    .page4_p,
    .page4_otp {
      padding: 0 0 0 0 !important;
      margin: 0 0 0 0 !important;
      line-height: .5rem !important;
      font-size: 15px !important;
    }

    .item1 {
      padding: 0 !important;
      margin: 0 !important;
    }

    .cs-form {
      padding: 20px !important;
    }
  }

  @media only screen and (max-width: 530px) {
    .page2_head {
      padding: 0 !important;
      margin: 0 !important;
      line-height: .5rem !important;
      font-weight: 900 !important;
      line-height: 22px !important;
      font-size: 19px !important;
    }

    .page2_p,
    .page4_p,
    .page4_otp {
      padding: 0 0 0 0 !important;
      margin: 0 0 0 0 !important;
      line-height: .3rem !important;
      font-size: 10px !important;
    }

    .cs-form {
      padding: 0 0 0 0 !important;
    }
  }

  .item1 {
    padding: 1rem 1.5rem !important;
  }

  .item2 {
    padding: 0 !important;
    margin-top: 1.8rem;
  }

  .resend_mail:hover {
    opacity: .9 !important;
  }

  .page2_p,
  .page4_p {
    padding-right: 3.5rem;
    padding-left: 3rem;
    font-size: 1rem !important;
    line-height: 1.7rem !important;
    text-align: left !important;
  }

  .page4_otp {
    font-size: 1rem !important;
    line-height: 1.7rem !important;
    text-align: left !important;
  }

  .buttons {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 3rem !important;
  }

  .btn1,
  .btn2,
  .pass_btn {
    background-color: #233067 !important;
    border-width: 0 !important;
    border-radius: 4px !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
    font-size: 13.5px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 20, 0.08) !important;
    transition: 0.3s !important;
    height: 40px !important;
  }

  .pass_btn {
    padding-left: 19px !important;
    padding-right: 19px !important;
    font-size: 15px !important;
    margin-top: 1rem !important;
  }

  #pass {
    display: grid !important;
    grid-template-areas:
      'pass pass pass pass pass eye' !important;
    gap: 7px !important;
    align-items: center !important;
  }

  #pass .pass {
    grid-area: pass !important;
  }

  #pass .eye {
    grid-area: eye !important;
    padding: .5rem !important;
  }

  .resend_mail {
    border: none !important;
    background: none !important;
    color: #233067 !important;
    cursor: pointer !important;
  }

  .resend_mail:after {
    display: block !important;
    content: '' !important;
    border-bottom: solid 2px #233067 !important;
  }

  .resend_mail:hover {
    /* box-shadow: 0 3px 15px 0 rgba(90, 122, 190, 0.25)!important;
  opacity: .8!important; */
    background: none !important;

  }

  .calendy {

    color: #233067 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #233067 !important;
    border: none !important;
    padding: 0 !important;
    background: none !important;
    border-bottom: 1px solid #233067 !important;

  }