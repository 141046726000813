.po-header {
    background-color: white;
}

.poHeaderClass {
    background-color: #233067;
}

/* .scroll-inner-table
{
    width: 90%;
} */

#main-table th  {
    background-color: #233067;
    color: white;
}

#table th  {
    background-color: lightgray;
    color: black;
}

#table5 th  {
    background-color: lightgray;
    color: black;
}