@import url(https://fonts.googleapis.com/css?family=Lato|Montserrat|Open+Sans|Roboto+Condensed|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese);
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  font-family: 'Roboto', sans-serif !important;
  letter-spacing: 0.5px !important;
  max-height: 100%;
  -webkit-touch-callout: none;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
  /* border-radius: 50% !important; */
}

::-webkit-scrollbar-button {
  background: #fff !important;
}

::-webkit-scrollbar-track-piece {
  background: #fff !important;
}

::-webkit-scrollbar-thumb {
  background: #737373 !important;
  border-radius: 0.5rem !important;
}

.bg {
  /* background-image: url(./assets/Audire_Login.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* object-fit: cover; */
  /* float: left; */
  min-width: 50%;
  width: 50%;
  min-height: 100%;
}

.iFrame {
  height: 800px !important;
  width: calc(100%) !important;
}

/* .nav-icon {
  color: white !important;
} */
 

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
b {
  letter-spacing: 0.5px !important;
  /* color: #4a5568; */
  font-weight: 500;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

.username {
  color: #e2e8f0 !important;
}

.span {
  padding: 6px;
  /* width: 50%; */
  align-items: center;
  border-radius: 20px;
}

.success {
  border: 1px solid green;
}

.batch {
  padding: 7px;
  margin: 5px;
  margin-top: unset;
  border-radius: 20px;
  letter-spacing: 0.5px !important;
}

.danger {
  border: 1px solid #721c24;
}

.info {
  border: 1px solid #0c5460;
}

.ball {
  /* width: 40px; */
  border-radius: 15px;
  text-align: center;
  margin: 5px;
  /* padding : 5px; */
}


.header-class {
  position: static;
  width: 100%;
}

.table td,
.table th {
  padding: 5px;
  text-align: center;
  height: 15px;
}

.table th {
  white-space: nowrap;
}

option {
  border-radius: 5px !important;
}

.audire-header {
  letter-spacing: .1em;
  /* font-family: Georgia, Cambria, "Times New Roman", Times, serif; */
  font-size: 1.875rem;
  color: #233067;
  font-weight: 900;
}

select:disabled,
input:disabled {
  background-color: lightgrey !important;
}

body {
  min-width: 100px;
  min-height: 100%;
  /* font-family: 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 15px;
}

.card {
  width: 103% !important;
}

.card-div {
  margin-left: -40px !important;
  margin-top: -30px !important;
}


.loader {
  border: 16px solid red;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* .username {
} */

.logout {
  cursor: pointer;
}

.text-white {
  color: #cbd5e0 !important;
}

.text-navy {
  color: #233067 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.text-gray {
  color: #4a5568 !important;
}

.text-lightgray {
  color: #718096 !important;
  font-weight: 500 !important;
}

.text-black {
  color: #000 !important;
  font-weight: 500 !important;
}

.logout:hover {
  height: 80px !important;
  /* width: 50px !important; */
  border: 0px solid;
  border-radius: 30%;
  background-color: #03487c;
}

.table-input,
.table-input:disabled,
table>tbody>td>input,
table>tbody>td>select {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  width: 100% !important;
}

table>tbody>tr.selected {
  border-bottom: 3px solid #f00;
}

.recommendation-holder {
  border: 1px dashed black;
  background-color: lightgray;
  font-weight: bolder;
  padding: 5px;
  margin: 5px;
}

.recommendation {
  border: 1px solid black;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  background-color: lightcyan;
  cursor: pointer;
}

.table {
  white-space: nowrap;
}

.table>tbody>tr>td {
  width: 150px !important;
  overflow: hidden !important;
}

table.tds-recon-table {
  font-size: 18px !important;
}

.number-format {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: unset !important;
  padding-left: 3 !important;
  text-align: right !important;
  font-size: 20px !important;
  /* font-weight: bold !important; */
}

div.sc-jIZahH.gdHjnA.rdt_TableHead {
  background-color: rebeccapurple !important;
}

.recommendation:hover {
  box-shadow: 8px 5px 25px 2px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 8px 5px 25px 2px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 8px 5px 25px 2px rgba(0, 0, 0, 0.57);
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
  margin-top: 40px;
}

.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}


.recom-selected {
  background-color: lightgreen;
}

.modal-70w {
  min-width: 77vw !important;
}

table>tbody>tr>td>input[type="text"],
table>tbody>tr>td>select {
  border: 1px solid #233067 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  width: 100% !important;
}

table>tbody>tr>td>input[type="checkbox"] {
  height: unset !important;
  text-align: center !important;
  margin-left: 15px !important;
}

.scroll-table {
  max-height: 400px;
  max-width: 98%;
  overflow: scroll;
}

.audire-sidebar-header {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12' height='12'%3E%3Cpath fill='%239C92AC' fill-opacity='0.045' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E") !important;
  background-color: transparent !important;
  color: #edf2f7 !important;
  letter-spacing: .05em !important;
  font-weight: 900 !important;
  font-size: 1.5rem !important;
  margin-top: -40px;
  text-align: center !important;
  font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.modal-dialog {
  margin-top: 80px;
}

.main-content {
  padding-top: 80px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 235px;
  height: 100%;
  position: relative;
  overflow: auto;
}

.application-loader {
  position: absolute;
  top: 0;
  left: 0;
  padding: 75px 0 0 230px;
  width: 100%;
  height: 100%;
}

.main-content-free {
  /* margin-top: -30px; */
  padding: 10px;
  /* margin-right: 10px; */
  /* margin-left: 230px; */
  min-height: 85vh;
}

.tree-container {
  /* border: 1px solid grey; */
  /* height: max-content !important; */
  /* min-height: 90vh; */
  /* overflow: auto; */
  padding: 10px;
  margin-top: 10px;
}

.page-header {
  border-bottom-width: 2px;
  padding: 5px;
  width: 70%;
  border-color: #233067;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.feild-header {
  /* border-bottom-width: 4px; */
  padding: 5px;
  margin-left: 5px;
  width: 95%;
  border-color: #cbd5e0;
  /* border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem; */
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.App {
  text-align: center;
  /* font-family: Georgia, Cambria, "Times New Roman", Times, serif; */
  /* font-family: 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  letter-spacing: .1em;
  width: 98% !important;
}

.customRowClass {
  max-height: 50px !important;
  height: 20px !important;
  padding-bottom: -5px !important;
  white-space: normal;
}

.customHeaderClass {
  vertical-align: middle !important;
}

.card-header {
  /* height: 50%; */
  background-color: rgb(35, 48, 103);
  color: #edf2f7 !important;
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.menu-bar {
  overflow-x: hidden;
  font-weight: 400;
  line-height: 20px !important;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-end;
}

.menu-bar::-webkit-scrollbar {
  width: 0px !important;
  /* Remove scrollbar space */
  background: transparent !important;
  /* Optional: just make scrollbar invisible */
}

/* .white-border {
  background-color: white !important;
} */

p {
  margin-bottom: 0;
}

.sub-menu-bar {
  margin-left: 10px !important;
}

.MuiTypography-body1 {
  font-size: 13px !important;
  line-height: 15px !important;
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid white;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12' height='12'%3E%3Cpath fill='%239C92AC' fill-opacity='0.045' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
}

.makeStyles-toolbar-5 {
  min-height: 43px !important;
}

.react-date-picker__inputGroup__input {
  border: 0px !important;
}

.react-date-picker__inputGroup__input:active {
  border: 0px !important;
  border-radius: 0px !important;
}

.bottom-menu-button {
  background-color: white;
  color: #000;
}

.sidebar-header-holder {
  /* background-color : white; */
  position: fixed;
  right: 0;
  /* height : 75px; */
  z-index: 999;
}

.sidebar {
  width: 100px;
}

.selectable-row {
  cursor: pointer !important;
}

.selectable-row:hover>td {
  border: 1px solid white;
  margin-top: -2px !important;
  margin-bottom: 3px !important;
  box-shadow: 17px 15px 18px -4px #233067;
  color: #fff !important;
  background-color: #233067;
}

.Chatbot {
  width: 1000px !important;
  background-color: red !important;
}

.rcw-widget-container {
  width: 87vw !important;
  max-width: 87vw !important;
  /* max-height: 200px; */
}

.rcw-sender {
  display: none !important;
}

.react-date-picker__wrapper {
  width: 108% !important;
  border-radius: 25px !important;
  padding: 1px !important;
  margin-left: -15px !important;
  font-size: 15px !important;
}

.rcw-conversation-container.active {
  width: 87vw !important;
  margin-right: 500px !important;
  height: 400px;
}

.rcw-conversation-container>.rcw-header {
  background-color: #233067 !important;
  height: 50px !important;
  padding: 0px !important;
  /* padding: 3px !important; */
  width: 87vw !important;
}


.selection-button {
  background-color: transparent !important;
  border: 1px dashed #233067 !important;
  color: #233067 !important;
  font-weight: 500;
  height: 50px !important;
}

.selection-icon {
  transform: scale(-1, 1);
}

.logo-container {
  width: 223px;
  padding: 2px;
}

.submenu {
  position: absolute;
  z-index: 99999;
  left: 175px;
  top: 305px;
  color: black;
  white-space: nowrap;
  background-color: white;
  width: 275px;
  max-height: 333px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid;
}

.profile-pic {
  object-fit: cover;
  width: 100%;
  height: 90px;
}

.ruler {
  height: 3px;
  margin: 5px;
  margin-left: 0px;
  width: 100%;
  background-color: white;
}

.menubar {
  width: 14rem !important;
  background-color: #233067;
  height: 100vh;
  color: white;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.alert-error {
  color: #b30000;
  background-color: #ffcccc;
  border: 1px solid #b30000;
  ;
}

.alert-box {
  z-index: 99999999999999999999;
  position: fixed;
  right: 15px;
  top: 10px;
}

.alert-box-with-app-bar {
  z-index: 99999999999999999999;
  position: fixed;
  right: 15px;
  top: 80px;
}

.card-body,
.card-body p {
  border-color: #cbd5e0 !important;
  border-bottom-width: 1px !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  box-sizing: inherit !important;
  -webkit-tap-highlight-color: transparent !important;
  border-width: 0 !important;
  border-style: solid !important;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  background: #fafafa !important;
  color: #233067 !important;
}

.login-textbox {
  padding: 7px !important;
  border-bottom: 1px solid black !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  outline: none !important;
  border-radius: 0 !important;
}

.login-textbox:focus {
  border: 0 !important;
  outline: none !important;
  border-bottom: 1px solid black !important;
}

input {
  background-color: white !important;
}

.table-holder {
  max-height: 75vh !important;
  overflow: auto;
}

i.icon-with-title {
  border-bottom: 1px dashed;
  text-decoration: none
}

i.icon-with-title:hover {
  cursor: help;
  position: relative
}

i.icon-with-title span {
  display: none
}

i.icon-with-title:hover span {
  font-weight: 600;
  font-size: large;
  border: #c0c0c0 1px solid;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 100;
  background: #f0f0f0 no-repeat 100% 5%;
  left: 0px;
  margin: 10px;
  width: 250px;
  position: absolute;
  bottom: auto;
  top: auto;
  text-decoration: none
}

input:not(.MuiInputBase-input),
select:not(.MuiInputBase-input) {
  border: 1px solid darkgray;
  height: 2rem !important;
  border-radius: 2px !important;
  /* padding: 5px; */
  outline: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}

input[type=radio] {
  height: inherit !important;
}

select[multiple] {
  height: auto !important;
}

/* .css-2613qy-menu */
#react-select-2-listbox {
  z-index: 10 !important;
}

.scroll-body {
  height: 680px;
  overflow: auto;
}

input#date-picker-inline {
  border: 0 !important;
  height: 1.1876em !important;
  border-radius: unset !important;
  padding: 6px 0 7px;
  outline: unset !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;

}

.pagination-select {
  border: unset !important;
  height: unset !important;
  border-radius: unset !important;
  /* padding: 10px !important; */
  outline: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;

}

/* button {
  border-radius: 2.25rem !important;
} */

.form-control {
  padding-bottom: 3px !important;
  padding-left: 12px !important;
  padding-top: 0 !important;
}

input:not(.MuiInputBase-input):focus,
select:not(.MuiInputBase-input):focus {
  border: 2px solid #233067;
  outline: none !important;
}

.error {
  border-bottom: 1px solid red;
}

.warning {
  border: 1px solid #856404;
}

.alert {
  position: fixed;
  top: 13px !important;
  right: 10px !important;
  z-index: 99999;
}

.icon {
  margin-top: 25px;
  border-bottom: 1px solid black;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  max-height: 20px !important;
  max-width: 30px !important;
}

.btn {
  min-height: 2rem !important;
  height: auto !important;
}

.btn-purple {
  height: auto !important;
  background-color: #233067 !important;
  color: white !important;
  font-size: 14px;
  font-weight: 600;
  outline: 0 !important;
}


.wrapper {
  display: flex;
  flex-direction: column;
}

.bar-wrapper {
  margin-top: 5px;
  margin-left: 5px;
}

legend {
  width: auto !important;
}

/* .tree-wrapper {
  height: calc(100% - 25px);
} */

.previous {
  margin-left: 10px;
}

.next,
.collapse {
  margin-right: 10px;
  margin-left: 5px;
}

#app,
.app {
  height: 1000px;
}

input[id^=react-select-] {
  margin-top: -5px !important;
  margin-left: -10px !important;
}

.tree {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #21252B;
}

.m-node.placeholder {
  border: 1px dashed #1385e5;
}

.m-node .inner {
  color: #9DA5B4;
  font-size: 12px;
  font-family: Menlo;
}

.m-node .node {
  display: inline-block;
  width: 100%;
  padding: 8px 8px;
  font-size: 17px;
}

.m-node .node.is-active {
  background-color: #31363F;
}

.group {
  border: 1px solid #000;
  padding: 20px;
  margin: 10px;
  width: 50%;
}

.icon {
  padding: 10px;
}

.inspector {
  padding: 20px;
  border: 1px solid #ccc;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  /* background: #ccc; */
  padding: 10px;
  text-decoration: underline;
}


.tree-buttons {
  border: 0px;
  background: transparent;
  font-size: 12px;
  padding: 3px 2px;
}

.rst__rowTitle {
  font-weight: normal !important;
  font-size: 12px;
}

.rst__rowWrapper {
  padding: 10px 10px 0px 0 !important;
  height: 100%;
  box-sizing: border-box;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: #ccc !important;
}

.tree-wrapper {
  height: calc(100% - 25px);
}

.rst__collapseButton,
.rst__expandButton {
  appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #ccc !important;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.radio-button-label {
  margin-right: 10px;
}

.form-container,
.table-container,
.lable-container {
  border: 1px dashed #ccc;
  padding: 10px !important;
  margin-bottom: 15px;
}

.division-title {
  /* padding: 5px 0px; */
  /* text-transform: uppercase; */
  text-align: CENTER;
  font-size: 18px;
  font-weight: bold;

}

.refresh-button {
  position: absolute !important;
  right: 30px !important;
}

.update-button {
  position: absolute !important;
  right: 60px !important;
}

.process-card {
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.next,
.collapse {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.card-header {
  padding: 10px !important;
}

.card-header button {
  width: 100%;
  background-color: #233067;
  color: white;
  border: 0px;
}

.table-container {
  overflow: overlay !important;
}

.rowViewIcon {
  transform: rotate(90deg) !important;
}

.btn-teal {
  background-color: #233067 !important;
  border-radius: 2.25rem;
  color: white !important;
  font-size: 14px;
  font-weight: 600;
  outline: 0 !important;
}

/* input:focus {
  border-bottom: 1px solid red;
  border-left: 0;
  border-right: 0;
  border-top: 0;
} */

*:focus:not(.MuiDataGrid-cell) {
  outline: none !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media only screen and (max-width : 599px) {

  .main-content {
    margin-left: 30px;
  }
}


@media only screen and (max-width : 800px) {
  .rcw-conversation-container.active {
    width: 87vw !important;
    margin-right: 500px !important;
    height: 100vh;
  }

}

@media only screen and (max-width : 767px) {

  .card {
    width: 95% !important;
    margin-left: 24px;
  }


}

.App-header {
  background-color: #233067;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.field-container {
  padding: 15px
}


input.react-select-2-input,
input:focus.react-select-2-input,
div.css-1pndypt-Input {
  border: 0px !important;
  min-width: 0px !important;
  margin-left: -10px !important;
  margin-top: -5px !important;
  grid-area: unset !important;
}

.css-1s2u09g-control,
.css-1s2u09g-control>div,
.css-1s2u09g-control>div>div {
  min-height: unset !important;
  max-height: 2rem !important;
  padding: 0px !important;
  padding-left: 3px !important;
}

.css-1s2u09g-control {
  border: 1px solid darkgray !important;
  border-radius: 0px !important;
}

.VrGcV div {
  overflow: unset !important;
}

/* .gdHjnA{
  z-index: 0 !important;
  background-color: red !important;
} */
.module-container {
    border: 1px solid black;
    border-radius: 19px;
    padding: 15px;
    color: #233067 !important;
    background-color: white;
    margin: 10px;
    font-weight: 500;
    white-space: normal;
    cursor: pointer;
    min-width: 350px;
    max-width: 350px;
    min-height: 136px !important;
}

.module-container:hover {
    border: 1px solid white;
    /* margin-top: -8px !important;
    margin-bottom: 10px !important; */
    box-shadow: 17px 15px 18px -4px #233067;
    color: white !important;
    background-color: #233067;
}

@media only screen and (max-width : 767px)
{
    .mobile-25
    {
        width: 25%;
    }

    .mobile-75 
    {
        width: 75%;
    }
}

.leases {
  background-color: lightgray;
  margin-top: 5px !important;
  width: 95% !important;
  /* height: 5rem; */
  padding: 5px;
  cursor: pointer;
}

.leases:hover {
  border: 1px solid green;
  margin-top: 8px !important;
  box-shadow: 13px 6px 30px -5px rgba(0, 0, 0, 0.75) !important;
}

.icon-btn {
  cursor: pointer;
}


table.BootstrapTable th {
  position: sticky;
  top: 0 !important;
  z-index: 1;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #d9d9d9;
  color: black;
  /* border: 1px solid #000 !important; */
}

table.BootstrapTable {
  height: 10px !important;
  width: 100% !important;
  border-collapse: separate;
  padding-bottom: 0px !important;
}

table.BootstrapTable thead.BootstrapTable th {
  vertical-align: middle !important;
  border:  1px solid #000 !important;
}

table.BootstrapTable td {
  vertical-align: top !important;
  white-space: nowrap !important;
  color: black;
}

table.acc-table th:nth-child(1),
table.acc-table td:nth-child(1),
table.acc-table th:nth-child(2),
table.acc-table td:nth-child(2),
table.acc-table th:nth-child(3),
table.acc-table td:nth-child(3)
{
  position: sticky;
  left: 3px;
  /* border: 0.5px solid #bfbfbf; */
  border:  1px solid #d9d9d9;
  /* background-color: red; */
}

table.acc-table th:nth-child(1),
table.acc-table th:nth-child(2),
table.acc-table th:nth-child(3)
{
  z-index: 5;
}


table.acc-table th:nth-child(2),
table.acc-table td:nth-child(2)
{
  left: 200px;
}

table.acc-table th:nth-child(3),
table.acc-table td:nth-child(3)
{
  left: 290px;
}

table.BootstrapTable p {
  margin-bottom: 0rem !important;
}

table.BootstrapTable td,
table.BootstrapTable th {
  padding: 2px !important;
}


#lease-table {
  margin-left: 0px !important;
}

.active-lease {
  border: 2px solid green;
}

.lease-card {
  width: 110% !important;
}

.flex-screen {
  /* display: flex; */
  background-color: white;
  margin: auto;
  width: 80%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  /* border: 3px solid #73AD21; */
  /* margin-top: 315px; */
  /* padding-bottom: 30px; */
  text-align: center;
}

.text-info {
  /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  font-weight: 600;
  color: #4a5568 !important;
  font-size: 1.25rem;
  letter-spacing: .025em;
  -webkit-font-smoothing: antialiased;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spin {
animation-name: spin;
animation-duration: 4000ms;
animation-iteration-count: infinite;
animation-timing-function: linear;
}

.scroll-div {
  height: 77vh;
  margin-left: -12px !important;
  overflow: scroll;
}

@media only screen and (max-width: 767px) {

  #lease-table {
    margin-left: -40px !important;
    width: 112% !important;
  }

  
  table.acc-table th:nth-child(1),
  table.acc-table th:nth-child(2),
  table.acc-table th:nth-child(3)
  {
    position: sticky;
    border:  1px solid #d9d9d9 !important;
    top: 0 !important;
    left: auto !important;
  }
  
  table.acc-table td:nth-child(1),
  table.acc-table td:nth-child(2),
  table.acc-table td:nth-child(3)
  {
    position: unset !important;
    border:  1px solid #d9d9d9 !important;
  }




}

/* table.acc-table thead tr.customRowClass th:first-child,
table.acc-table tbody tr.customRowClass td:first-child {
  background: yellow;
  position:fixed !important; 
  top: auto;
  left: 0 !important;
}


table.acc-table thead tr.customRowClass th:nth-child(2),
table.acc-table tbody tr.customRowClass td:nth-child(2) {
  padding-left: 7em;
} */
.flex-screen {
    /* display: flex; */
    background-color: white;
    margin: auto;
    margin-top: 5px;
    width: 80%;
    height: -moz-fit-content !important;
    height: fit-content !important;
    position: absolute;
    padding: 15px;
    padding-top: 55px;
    top: 0;
    bottom: 0;
    right: 0;
    /* max-height: 90vh !important; */
    /* overflow: scroll; */
    /* border: 3px solid #73AD21; */
    /* margin-top: 315px; */
    /* padding-bottom: 30px; */
    text-align: center;
}

@media only screen and (max-width : 720px) {    
    .login-sub1 {
        display: none;    
    }   
}

.login-padding {
    padding: 5rem;
}

@media only screen and (max-width : 1242px) {    
    .login-padding {
        padding: 3rem;    
    }   
}
@media only screen and (max-width : 1000px) {    
    .login-padding {
        padding: 1rem;    
    }   
}

@media only screen and (max-width : 1000px) {    
    .login-padding {
        padding: 1rem;    
    }   
}

.grey-text {
    color: #718096;
    text-align: center;
}

.ui_link {
    color: #4a5568;
    cursor: pointer;
}

.ui_link :hover {
    color: black;
    text-decoration: underline;
    background-color: transparent;
}

.div-container {
    border: 1px solid;
}


.org-container {
    padding: 0px;
    cursor: pointer;
    margin: 19px;
    align-self: baseline;
}


.org-container:hover {
    margin-top: 3px;
    color: white;
    box-shadow: 11px 13px 26px 8px rgba(35, 48, 102, 0.77);
}

.logo-box {
    /* border: 1px solid #233067; */
    width: 100%;
}

@media only screen and (max-width : 767px)
{
    .org-container{
        height: 50px;
        width: 78px;
    }
}
  @media (min-width: 500px) {

    .cs-form {
      padding: 30px 42px 32px !important;
      /* border: 1px solid #CDCDCD!important; */
      border-radius: 8px !important;
    }
  }

  /* .cs-form {
    margin-top: 54px!important;
  } */

  @media (min-width: 1200px) {

    /* .cs-form {
      padding-top: 54px!important;
    } */
  }

  @media (min-width: 500px) {

    .cs-form__title {
      /* position: absolute!important; */
      /* top: -16px!important; */
      margin-bottom: 0 !important;
      font-size: 18px !important;
      /* margin-top: 54px!important; */

    }
  }

  @media (min-width: 1200px) {

    .cs-form__title {
      font-size: 20px !important;
      /* top: -18px!important; */
    }
  }

  .cs-form__title,
  .page2_head,
  .page4_head {
    color: #00000A !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 900 !important;
    line-height: 32px !important;
    font-size: 21px !important;
    background: #fff !important;
    padding: 0 12px !important;
    margin-left: -12px !important;
    margin-bottom: 32px !important;
  }

  #signup {
    display: block !important;
    margin-top: 0em !important;
  }

  .cs-form__group {
    margin-bottom: 24px !important;
  }

  .cs-form__label {
    color: #727272 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    margin-bottom: 12px !important;
  }

  .cs-form__field {
    position: relative !important;
    font: inherit !important;
  }

  .cs-form__control {
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
    min-width: 42px !important;
    height: 46px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    font-size: 16px !important;
    border: 1px solid #CDCDCD !important;
    border-radius: 4px !important;
    background-color: #FFFFFF !important;
    transition: border-color ease-in-out 0.15s !important;
    color: #202022 !important;
    padding: 0 16px !important;
  }

  /* .emailInput , .phoneInput {

      overflow: visible!important;
      font: inherit!important;
  }

  button,
  input {
    overflow: visible!important;
    font: inherit!important;
  } */

  /* #phone {
    width: 100% !important!important;
  } */

  @media (min-width: 500px) {

    .container {
      max-width: 500px !important;
      margin: auto !important;
    }
  }

  @media (min-width: 1200px) {

    .container {
      max-width: 630px !important;
      margin: auto !important;
    }
  }

  .checkbox {
    width: 38px !important;
    height: 18px !important;
    position: absolute !important;
  }

  .cs-checkbox__label {
    cursor: pointer !important;
    display: inline-block !important;
    color: #727272 !important;
    font-weight: normal !important;
  }


  .cs-form__title,
  .cs-form__label {
    text-align: left;
    /* font-weight: 700!important;  */
  }

  .cs-checkbox__value {
    vertical-align: middle !important;
    margin-left: 2.5rem !important;
    font: inherit !important;

  }

  .cs-form__btn {
    margin-top: 1rem !important;
    background-color: #233067 !important;
    border-width: 0 !important;
    border-radius: 4px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 20, 0.08) !important;
    transition: 0.3s !important;
    /* line-height: 52px!important; */
    height: 50px !important;
    width: 100% !important;
  }

  /* @media only screen and (min-width: 1024px) {
  .cs-form__btn {
    height: 56px!important;
    line-height: 56px!important;
  }
}
@media only screen and (max-width: 1023px) {
  .cs-form__btn {
    line-height: 52px!important;
    height: 52px!important;
    width: 100%!important;
  }
} */
  .cs-form__btn:hover,
  .btn1:hover,
  .btn2:hover,
  .pass_btn:hover {
    color: #ffffff !important;
    box-shadow: 0 3px 15px 0 rgba(90, 122, 190, 0.25) !important;
    opacity: .8 !important;
  }

  .cs-form__btn[disabled] {
    filter: grayscale(100%) !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }

  .cs-form__control.has-error {
    border: 2px solid #E74C3C !important;
  }

  .help-block {
    font-size: 1.1rem !important;
    z-index: 2 !important;
    position: absolute !important;
    z-index: 10 !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: .8rem !important;
    color: #E74C3C !important;
  }

  .email_info_addon {
    padding-top: 1rem !important;
    padding-bottom: 0 !important;
    color: #E74C3C !important;
  }

  .page2_head {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
  }

  @media only screen and (max-width: 1290px) {
    .page2_head {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 1rem !important;
      font-weight: 900 !important;
      line-height: 32px !important;
      font-size: 19px !important;
    }

    .page2_p,
    .page4_p,
    .page4_otp {
      padding: 0 0 0 0 !important;
      margin: 0 0 0 0 !important;
      line-height: .5rem !important;
      font-size: 15px !important;
    }

    .item1 {
      padding: 0 !important;
      margin: 0 !important;
    }

    .cs-form {
      padding: 20px !important;
    }
  }

  @media only screen and (max-width: 530px) {
    .page2_head {
      padding: 0 !important;
      margin: 0 !important;
      line-height: .5rem !important;
      font-weight: 900 !important;
      line-height: 22px !important;
      font-size: 19px !important;
    }

    .page2_p,
    .page4_p,
    .page4_otp {
      padding: 0 0 0 0 !important;
      margin: 0 0 0 0 !important;
      line-height: .3rem !important;
      font-size: 10px !important;
    }

    .cs-form {
      padding: 0 0 0 0 !important;
    }
  }

  .item1 {
    padding: 1rem 1.5rem !important;
  }

  .item2 {
    padding: 0 !important;
    margin-top: 1.8rem;
  }

  .resend_mail:hover {
    opacity: .9 !important;
  }

  .page2_p,
  .page4_p {
    padding-right: 3.5rem;
    padding-left: 3rem;
    font-size: 1rem !important;
    line-height: 1.7rem !important;
    text-align: left !important;
  }

  .page4_otp {
    font-size: 1rem !important;
    line-height: 1.7rem !important;
    text-align: left !important;
  }

  .buttons {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 3rem !important;
  }

  .btn1,
  .btn2,
  .pass_btn {
    background-color: #233067 !important;
    border-width: 0 !important;
    border-radius: 4px !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
    font-size: 13.5px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 20, 0.08) !important;
    transition: 0.3s !important;
    height: 40px !important;
  }

  .pass_btn {
    padding-left: 19px !important;
    padding-right: 19px !important;
    font-size: 15px !important;
    margin-top: 1rem !important;
  }

  #pass {
    display: grid !important;
    grid-template-areas:
      'pass pass pass pass pass eye' !important;
    grid-gap: 7px !important;
    gap: 7px !important;
    align-items: center !important;
  }

  #pass .pass {
    grid-area: pass !important;
  }

  #pass .eye {
    grid-area: eye !important;
    padding: .5rem !important;
  }

  .resend_mail {
    border: none !important;
    background: none !important;
    color: #233067 !important;
    cursor: pointer !important;
  }

  .resend_mail:after {
    display: block !important;
    content: '' !important;
    border-bottom: solid 2px #233067 !important;
  }

  .resend_mail:hover {
    /* box-shadow: 0 3px 15px 0 rgba(90, 122, 190, 0.25)!important;
  opacity: .8!important; */
    background: none !important;

  }

  .calendy {

    color: #233067 !important;
    cursor: pointer !important;
    border-bottom: 1px solid #233067 !important;
    border: none !important;
    padding: 0 !important;
    background: none !important;
    border-bottom: 1px solid #233067 !important;

  }
.po-header {
    background-color: white;
}

.poHeaderClass {
    background-color: #233067;
}

/* .scroll-inner-table
{
    width: 90%;
} */

#main-table th  {
    background-color: #233067;
    color: white;
}

#table th  {
    background-color: lightgray;
    color: black;
}

#table5 th  {
    background-color: lightgray;
    color: black;
}
.tile-holder {
    background-color: #c5cae9;
    border: 1px solid #233067;
    border-radius: 15px;
    /* padding: 20px; */
    margin: 5px;
    max-width: 350px !important;
    /* margin-left: 10px; */
}

.tile-holder-yellow {
    background-color: #c5cae9;
    border: 1px solid #233067;
    border-radius: 15px;
    /* padding: 20px; */
    margin: 5px;
    max-width: 350px !important;
}

@media only screen and (max-width : 1200px) {

    .tile-holder {
        margin: 10px;
        max-width: unset !important;
    }

    .tile-holder-yellow {
        margin: 10px;
        max-width: unset !important;
    }
}

.progress-bar {
    cursor: pointer;
}

.progress-bar:hover {
    position: unset !important;
    transform: scale(1.25);
}
.show-excel {
    margin-top: 30px;
    height: 70vh;
    overflow: auto;
}
.leases {
    background-color:  lightgray;
    margin-top: 30px;
    width: 80%;
    /* height: 5rem; */
    padding: 5px;
    cursor: pointer;
}

.leases:hover {
    margin-top: 25px;
    box-shadow: 15px 14px 6px -5px rgba(0,0,0,0.75);
}

.flex-screen-404 {
    /* display: flex; */
    background-color: white;
    margin: auto;
    margin-top: 100px;
    width: 70%;
    height: auto;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    /* border: 3px solid #73AD21; */
    /* margin-top: 315px; */
    /* padding-bottom: 30px; */
    text-align: center;
}

.text-info {
    /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
    font-weight: 600;
    color: #4a5568 !important; 
    font-size: 1.25rem;
    letter-spacing: .025em;
    -webkit-font-smoothing: antialiased;
}

.wip-image {
    position: absolute;
    top : 0;
    left: 0;
}

table.report-table td {
    background-color: white;
}

table.report-table th:nth-child(1),
table.report-table td:nth-child(1),
table.report-table th:nth-child(2),
table.report-table td:nth-child(2),
table.report-table th:nth-child(3),
table.report-table td:nth-child(3),
table.report-table th:nth-child(4),
table.report-table td:nth-child(4)
{
  position: sticky;
  left: 3px;
  /* border: 0.5px solid #bfbfbf; */
  border:  1px solid #d9d9d9;
}

table.report-table th:nth-child(1),
table.report-table th:nth-child(2),
table.report-table th:nth-child(3),
table.report-table th:nth-child(4)
{
  z-index: 5;
}


table.report-table th:nth-child(2),
table.report-table td:nth-child(2)
{
  left: 80px;
}

table.report-table th:nth-child(3),
table.report-table td:nth-child(3)
{
  left: 290px;
}

table.report-table th:nth-child(4),
table.report-table td:nth-child(4)
{
  left: 363px;
}

.show-excel {
    margin-top: 30px;
    height: 70vh;
    overflow: auto;
}
/* 
.react-bootstrap-table-pagination-list {
    margin-top: 50px !important;
} */

#subtable thead th
{
    text-align: center;
    background-color: #233067;
    color: white;
    vertical-align: middle !important;
    border: 1px solid #bfbfbf !important;
}

/* table tr:nth-child(even) {
    background-color: #c6cdeb;
} */

#subtable th {
    position: sticky;
    z-index: 999;
    top: 0;
}

.scroll-div {
    height: 85vh;
    width: 100%;
    margin-left: -12px !important;
    overflow: scroll;
}
.leases {
    background-color:  lightgray;
    margin-top: 30px;
    width: 80%;
    /* height: 5rem; */
    padding: 5px;
    cursor: pointer;
}

.leases:hover {
    margin-top: 25px;
    box-shadow: 15px 14px 6px -5px rgba(0,0,0,0.75);
}

.flex-screen {
    /* display: flex; */
    background-color: white;
    margin: auto;
    width: 80%;
    height: auto;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    /* border: 3px solid #73AD21; */
    /* margin-top: 315px; */
    /* padding-bottom: 30px; */
    text-align: center;
}

.text-info {
    /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
    font-weight: 600;
    color: #4a5568 !important; 
    font-size: 1.25rem;
    letter-spacing: .025em;
    -webkit-font-smoothing: antialiased;
}

#file {
    display: none;
}

.uploader {
    min-width: 300px;
}

.header_div {
  align-items: center;
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: sans-serif;
}

.header_div_selection_screen {
align-items: center;
text-align: center;
font-size: x-large;
font-weight: bolder;
font-family: sans-serif;
}

.detail {
  margin-right: 5px;
}

.highlight {
  background-color: #a0d3ff; /* Light highlight color */
}

.detail label {
  margin-right: 10px;
}

.preview-table {
  border: #233067 2px dashed;
  padding: 10px;
  margin: 10px;
  background-color: lightblue;
  transition: 2s;
}

.display-table {
  color: #233067;
}

.spacing {
  width: 100% !important;
  text-align: left;
  height: 150px;
  margin-bottom: 5px;
      margin-top: 3px;
      font-family: cursive, sans-serif;
      outline: 0;
      /* background: #2ecc71; */
      /* color: #fff; */
      /* border: 1px solid crimson; */
      padding: 4px;
      border-radius: 9px;
}

.spacing1{
  width: 80% !important;
  text-align: left;
}

.center-screen {
display: flex;
justify-content: center;
align-items: center;
text-align: center;
min-height: 60vh;
}

#table_styling{
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  overflow: scroll;
  border-collapse: collapse;
}

#table_styling td, #table_styling th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table_styling tr:nth-child(even){background-color: #f2f2f2;}

#table_styling tr:hover {background-color: #ddd;}

#table_styling th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;
background-color: #233067;
color: white;
}

.wrapper{
  align-items: center;
}

.buttonConfirm {
  position: absolute;
  top: 50%;
}

.table-wrapper{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

bodY {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, #00BAF7, #1E46A3);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* body{
  background:#eee;
} */
.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #d2d6de;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
  border: 1px solid #d2d6de;
  border-radius: 0;
  padding: 6px 12px;
  height: 34px
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 10px
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  margin-top: -3px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px
}

.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
  border: 1px solid #d2d6de;
  border-radius: 0 !important;
  padding: 6px 12px;
  height: 40px !important
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 6px !important;
  right: 1px;
  width: 20px
}

.red-dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -10px;
  right: -10px;
}
.leases {
  background-color: lightgray;
  margin-top: 5px !important;
  width: 95% !important;
  /* height: 5rem; */
  padding: 5px;
  cursor: pointer;
}

.leases:hover {
  border: 1px solid green;
  margin-top: 8px !important;
  box-shadow: 13px 6px 30px -5px rgba(0, 0, 0, 0.75) !important;
}

.icon-btn {
  cursor: pointer;
}


table.BootstrapTable th {
  position: sticky;
  top: 0 !important;
  z-index: 1;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #d9d9d9;
  color: black;
  /* border: 1px solid #000 !important; */
}

table.BootstrapTable {
  height: 10px !important;
  width: 100% !important;
  border-collapse: separate;
  padding-bottom: 0px !important;
}

table.BootstrapTable thead.BootstrapTable th {
  vertical-align: middle !important;
  border:  1px solid #000 !important;
}

table.BootstrapTable td {
  vertical-align: top !important;
  white-space: nowrap !important;
  color: black;
}

table.acc-table th:nth-child(1),
table.acc-table td:nth-child(1),
table.acc-table th:nth-child(2),
table.acc-table td:nth-child(2),
table.acc-table th:nth-child(3),
table.acc-table td:nth-child(3)
{
  position: sticky;
  left: 3px;
  /* border: 0.5px solid #bfbfbf; */
  border:  1px solid #d9d9d9;
  /* background-color: red; */
}

table.acc-table th:nth-child(1),
table.acc-table th:nth-child(2),
table.acc-table th:nth-child(3)
{
  z-index: 5;
}


table.acc-table th:nth-child(2),
table.acc-table td:nth-child(2)
{
  left: 200px;
}

table.acc-table th:nth-child(3),
table.acc-table td:nth-child(3)
{
  left: 290px;
}

table.BootstrapTable p {
  margin-bottom: 0rem !important;
}

table.BootstrapTable td,
table.BootstrapTable th {
  padding: 2px !important;
}


#lease-table {
  margin-left: 0px !important;
}

.active-lease {
  border: 2px solid green;
}

.lease-card {
  width: 110% !important;
}

.flex-screen {
  /* display: flex; */
  background-color: white;
  margin: auto;
  width: 80%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  /* border: 3px solid #73AD21; */
  /* margin-top: 315px; */
  /* padding-bottom: 30px; */
  text-align: center;
}

.text-info {
  /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  font-weight: 600;
  color: #4a5568 !important;
  font-size: 1.25rem;
  letter-spacing: .025em;
  -webkit-font-smoothing: antialiased;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spin {
animation-name: spin;
animation-duration: 4000ms;
animation-iteration-count: infinite;
animation-timing-function: linear;
}

.scroll-div {
  height: 77vh;
  margin-left: -12px !important;
  overflow: scroll;
}

@media only screen and (max-width: 767px) {

  #lease-table {
    margin-left: -40px !important;
    width: 112% !important;
  }

  
  table.acc-table th:nth-child(1),
  table.acc-table th:nth-child(2),
  table.acc-table th:nth-child(3)
  {
    position: sticky;
    border:  1px solid #d9d9d9 !important;
    top: 0 !important;
    left: auto !important;
  }
  
  table.acc-table td:nth-child(1),
  table.acc-table td:nth-child(2),
  table.acc-table td:nth-child(3)
  {
    position: unset !important;
    border:  1px solid #d9d9d9 !important;
  }




}

/* table.acc-table thead tr.customRowClass th:first-child,
table.acc-table tbody tr.customRowClass td:first-child {
  background: yellow;
  position:fixed !important; 
  top: auto;
  left: 0 !important;
}


table.acc-table thead tr.customRowClass th:nth-child(2),
table.acc-table tbody tr.customRowClass td:nth-child(2) {
  padding-left: 7em;
} */
.calc {
    width: 100% !important;
    text-align: left;
}

label {
    font-weight: bolder;
    font-size: 18px;
}


h4 {
    color: orange;
    width: 300px;
    font-weight: 600;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: left;
}

.calc-display {
    color: #233067;
}

.recom-box {
    border: #233067 2px dashed;
    padding: 10px;
    margin: 10px;
    background-color: lightblue;
    transition: 2s;
}

.calc-table {
    border: #233067 2px dashed;
    background-color: lightblue;
    transition: 2s;
    /* border-collapse: collapse dashed; */
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table.calc-table>tbody>tr>td {
    text-align: center;
    padding: 8px;
}

table.calc-table>thead>tr>th {
    text-align: center;
    padding: 8px;
}
#table-css {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
 #table-css th {
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 1rem;
  }
  #table-css td {
    border: 1px solid #ddd;
    /* padding: 5px; */
    font-size: .85rem;
    text-align: center;
  }
  
  /* #table-css tr:nth-child(even){background-color: #f2f2f2;} */
  
  /* #table-css tr:hover {background-color: #ddd;} */
  
  #table-css th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    background-color: #e9eaf0;
    color: black;
    text-align: center;
    z-index: 1 !important;
  }

  /* #table-css {
    position: absolute;
    z-index: -1;
  } */
/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999999;
  
}
 
.box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: auto;
  max-height: 85vh;
  margin-top: calc(100vh - 85vh - 25px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 5px solid #dae634;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 150px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}


.modal-box {
  position: fixed;
  background: #f0f1f3;
  color: rgb(24, 19, 19);
  font-family: sans-serif;
  width: 75%;
  height: auto;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999; /* Higher than popup-box */
  padding: 10px;
  border: 2px solid #fdfbfb;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999998; /* Slightly lower than modal-box */
}

.similarity-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.similarity-table caption {
  font-weight: bold;
  font-size: large;
  font-family: sans-serif;
  color: black;
  margin-bottom: 5px;
  text-align: center;
  caption-side: top; 
}


.similarity-table th, .similarity-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.similarity-table th {
  background-color: #233067;
  color: #f2f2f2;
}

.similarity-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.similarity-table tr:hover {
  background-color: #233067;
  color:#f9f9f9;
}

.modal-box td {
  font-size: 14px;
  line-height: 1.5;
}

.modal-box button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-box button:hover {
  background-color: #03ce03;
}

.text-center {
  text-align: center;
}










                        /* mbp */

/* Popup style */
/* .popup-box { */
/* position: fixed; */
/* background: rgba(0, 0, 0, 0.5); More readable way to specify semi-transparency */
/* width: 100%;
height: 100vh;
top: 0;
left: 0;
z-index: 9999999;
} */

/* .box { */
/* position: absolute; Change from relative to absolute to ensure fixed positioning */
/* width: 90%;
left: 50%;
top: 50%;
transform: translate(-50%, -50%); Center the box */
/* height: auto;
max-height: 85vh;
background: #fff;
border-radius: 4px;
padding: 20px;
border: 5px solid #dae634;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: auto;
}

.close-icon {
cursor: pointer; */
/* position: absolute; Change from fixed to absolute */
/* right: 10px; Position relative to the .box */
/* top: 10px;
background: #ededed;
width: 25px;
height: 25px;
border-radius: 50%; */
/* line-height: 25px; Center the 'x' vertically */
/* text-align: center;
border: 1px solid #999;
font-size: 20px;
} */

body,
html,
#root,
.wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
}

/* * {
    user-select: none !important;
} */

.wrapper {
    display: flex;
    flex-direction: column;
}

.bar-wrapper {
    margin-top: 5px;
    margin-left: 5px;
}

legend {
    width: auto !important;
}

/* .tree-wrapper {
  height: calc(100% - 25px);
} */

.previous {
    margin-left: 10px;
}

.next,
.collapse {
    margin-right: 10px;
    margin-left: 5px;
}

#app,
.app {
    height: 1000px;
}

.tree {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #21252B;
}

.m-node.placeholder {
    border: 1px dashed #1385e5;
}

.m-node .inner {
    color: #9DA5B4;
    font-size: 12px;
    font-family: Menlo;
}

.m-node .node {
    display: inline-block;
    width: 100%;
    padding: 8px 8px;
    font-size: 17px;
}

.m-node .node.is-active {
    background-color: #31363F;
}

.group {
    border: 1px solid #000;
    padding: 20px;
    margin: 10px;
    width: 50%;
}

.icon {
    padding: 10px;
}

.inspector {
    padding: 20px;
    border: 1px solid #ccc;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    /* background: #ccc; */
    padding: 10px;
    text-decoration: underline;
}

.field-container {
    padding: 15px
}

.group-container {
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.tree-buttons {
    border: 0px;
    background: transparent;
    font-size: 12px;
    padding: 3px 2px;
}

.rst__rowTitle {
    font-weight: normal !important;
    font-size: 12px;
}

.rst__rowWrapper {
    padding: 10px 10px 0px 0 !important;
    height: 100%;
    box-sizing: border-box;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    position: absolute;
    content: '';
    background-color: #ccc !important;
}

.rst__collapseButton,
.rst__expandButton {
    appearance: none;
    border: none;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #ccc !important;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.radio-button-label {
    margin-right: 10px;
}

.form-container,
.table-container {
    border: 1px dashed #ccc;
    padding: 10px !important;
    margin-bottom: 15px;
}

.division-title {
    padding: 5px 0px;
    text-transform: uppercase;
    text-align: CENTER;
    font-size: 18px;
    font-weight: bold;

}

.next,
.collapse {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.card-header {
    padding: 0px !important;
}

.card-header button {
    width: 100%;
    background-color: #e4e4e4;
    border: 0px;
}

.table-container {
    overflow: overlay !important;
}

.rowViewIcon {
    transform: rotate(90deg) !important;
}
.popup {
    font-family: "Montserrat";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 9999;
}

.popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border: 0;
    border-radius: 0 0 8px 8px;
}
  
.basic_outline {
    display: flex;
    flex-direction: column;
}

.outline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
}

.content {
    margin-right: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
}

.input_content {
    padding: 5;
    font-size: 0.75rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.green_button {
    font-family: "Montserrat-Semibold", sans-serif;
    background-color: #22B378;
    color: #FFF;
    font-size: 0.85rem;
  }

  .red_button {
    font-family: "Montserrat-Semibold", sans-serif;
    background-color: #F76831;
    color: #FFF;
    font-size: 0.7rem;
  }
.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 60px 0px 60px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 1px solid #92b0b3;
}

.files {
    position: relative
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    /* background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png); */
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #f1f1f1;
}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    /* content: " or drag it here. "; */
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

#table_styling{
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    overflow: scroll;
    border-collapse: collapse;
}

#table_styling td, #table_styling th {
    border: 1px solid #ddd;
    padding: 8px;
}

#table_styling tr:nth-child(even){background-color: #f2f2f2;}

#table_styling tr:hover {background-color: #ddd;}

#table_styling th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #233067;
  color: white;
}
.sticky-div {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: auto;
    z-index: 100; 
}
.wrapper-table1{
    display: flex;
    flex-direction: column;
}

.wrapper-table2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    overflow-x: auto;
}

@media (min-width: 640px) {
    .sm\:-mx-6 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}

@media (min-width: 1024px) {
    .lg\:-mx-8 {
        margin-left: -2rem/* -32px */;
        margin-right: -2rem/* -32px */;
    }
}

.wrapper-table3{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    vertical-align: middle;
    display: inline-block;
    min-width: 100%;   
}

.wrapper-table4{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    overflow: hidden;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

@media (min-width: 640px) {
    .sm\:rounded-lg {
        border-radius: 0.5rem/* 8px */;
    }
}

.new-table{
    min-width: 100%;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.new-table-header{
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

#new-table th{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: left;
    font-size: 0.75rem/* 12px */;
    line-height: 1rem/* 16px */;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.new-table-body{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#new-table td{
    padding-left: 1.5rem/* 24px */;
    padding-right: 1.5rem/* 24px */;
    padding-top: 1rem/* 16px */;
    padding-bottom: 1rem/* 16px */;
    white-space: nowrap;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.div-container1{
    width:18rem;
    font-weight: 500;
    /* height: 20rem; */
}

.div-container2{
    /* --tw-bg-opacity: 1; */
    /* background-color: rgb(255 255 255 / var(--tw-bg-opacity)); */
    background-color: white;
    width:100%;
    padding:0.5rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-radius:0.25rem;
    border-style: groove;
    cursor: pointer;
}

.div-container22{
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bichevron-rotate{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ul-container{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    margin-top: 0.5rem;
    overflow-y: auto;
    width: 100%;
    /* position: absolute; */
    /* border-style: ridge; */
}

.ul-maxheight{

    max-height: 15rem;
}

.ul-minheight{
    max-height: 0px;
}

.div-container3{
    display: flex;
    align-items: center;
    /* padding-left: 0.5rem;
    padding-right: 0.5rem; */
    position: sticky;
    top: 0px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.aioutlinesearch{
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.placeholder-input{
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.select-input{
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 0.5rem;
}

.li-container{
    padding:0.5rem;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    --tw-bg-opacity: 1;
    /* background-color: rgb(2 132 199 / var(--tw-bg-opacity)); */
    background-color: whitesmoke;
    --tw-text-opacity: 1;
    /* color: rgb(255 255 255 / var(--tw-text-opacity)); */
    color: black;
    cursor: pointer;
    /* position: absolute; */
}

.li-container-special{
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.li-block{
    display:block;
}

.li-hidden{
    display: none;
}

.contin {
  display: flex;
  height: 100vh;
  transition: all 0.3s ease;
  width: 100%;
}

.left-panel {
  /* flex: 1; */
  /* padding: 20px; */
  border-right: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.3s ease;
  width: 250%;
}

.right-panel {
  /* flex: 0; */
  /* padding: 20px; */
  overflow-y: auto;
  transition: all 0.3s ease;
  height: 68vh;
}

.contin.split .left-panel {
  /* flex: 1; */
  width: 42%;
}

.contin.split .right-panel {
  /* flex: 2; */
  width: 120%;
  height: 100vh;
}

.split{
  width: 83%;
}

.close-btn {
  /* position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px; */
  background-color: #233067;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.close-btn:hover {
  background-color: #d32f2f;
}
.container_group{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.container_div{
    margin-right: 15;
    font-family: sans-serif;
    font-size: 15;
    font-weight: bold;
}

.container_select{
    padding: 5;
    border-radius: 5;
    border: 1px solid #ccc;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 60vh;
  }
.sidebar {
  /* background-color: #f0f0f0; */
  width: 60px;
  /* height: 664px;  */
  height: 97vh; /* Set the height to 100vh (viewport height) */
  overflow-x: hidden;
  transition: width 0.3s ease-in-out;
  position: sticky;
  top: 0;
  background: inherit;
  /* z-index: 1; */
}

.sidebar.one {
  background-color: #f0f0f0;
}

.sidebar.two {
  background-color: #EAEAEA;
}

.sidebar.one.open {
  width: 400px; /* Adjust this value for the expanded width */
}

.sidebar.two.open {
  width: 250px; /* Adjust this value for the expanded width */
}

.main_content {
  overflow-y: scroll;
  height: 97vh;
}

.main_content.closed.closed2 {
  width: 1430px; 
}

.main_content.open.open2 {
  width: 830px; 
}

.main_content.open.closed2 {
  width: 1050px; 
}

.main_content.closed.open2 {
  width: 1200px; 
}




.sidebar.closed {
  width: 30px; /* The initial small width */
}


.sidebar .toggle-container {
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 1;
}

.sidebar .toggle-button {
  cursor: pointer;
  text-align: right;
  padding: 10px;
  font-size: 20px;
}


.sidebar .content {
  padding: 10px;
}

.app-container {
  display: flex; /* This will display the sidebars in a row */
}


  



/* //////////////////////////////// */


.collapsible-section {
  border: 1px solid #ccc;
  overflow: hidden;
  max-height: 2000px;
  transition: max-height 0.2s ease-in-out; /* Apply the transition to the height property */
}

.collapsible-section.open {
  max-height: 2000px; /* Display the content when the section is open */
}

.collapsible-section.closed {
  max-height: 40px; /* Adjust this value to set the collapsed height */
}

.collapsible-section button {
  width: 100%;
}

.collapsible-section .content {
  padding: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
