#subtable thead th
{
    text-align: center;
    background-color: #233067;
    color: white;
    vertical-align: middle !important;
    border: 1px solid #bfbfbf !important;
}

/* table tr:nth-child(even) {
    background-color: #c6cdeb;
} */

#subtable th {
    position: sticky;
    z-index: 999;
    top: 0;
}

.scroll-div {
    height: 85vh;
    width: 100%;
    margin-left: -12px !important;
    overflow: scroll;
}