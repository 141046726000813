.sidebar {
  /* background-color: #f0f0f0; */
  width: 60px;
  /* height: 664px;  */
  height: 97vh; /* Set the height to 100vh (viewport height) */
  overflow-x: hidden;
  transition: width 0.3s ease-in-out;
  position: sticky;
  top: 0;
  background: inherit;
  /* z-index: 1; */
}

.sidebar.one {
  background-color: #f0f0f0;
}

.sidebar.two {
  background-color: #EAEAEA;
}

.sidebar.one.open {
  width: 400px; /* Adjust this value for the expanded width */
}

.sidebar.two.open {
  width: 250px; /* Adjust this value for the expanded width */
}

.main_content {
  overflow-y: scroll;
  height: 97vh;
}

.main_content.closed.closed2 {
  width: 1430px; 
}

.main_content.open.open2 {
  width: 830px; 
}

.main_content.open.closed2 {
  width: 1050px; 
}

.main_content.closed.open2 {
  width: 1200px; 
}




.sidebar.closed {
  width: 30px; /* The initial small width */
}


.sidebar .toggle-container {
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 1;
}

.sidebar .toggle-button {
  cursor: pointer;
  text-align: right;
  padding: 10px;
  font-size: 20px;
}


.sidebar .content {
  padding: 10px;
}

.app-container {
  display: flex; /* This will display the sidebars in a row */
}


  



/* //////////////////////////////// */


.collapsible-section {
  border: 1px solid #ccc;
  overflow: hidden;
  max-height: 2000px;
  transition: max-height 0.2s ease-in-out; /* Apply the transition to the height property */
}

.collapsible-section.open {
  max-height: 2000px; /* Display the content when the section is open */
}

.collapsible-section.closed {
  max-height: 40px; /* Adjust this value to set the collapsed height */
}

.collapsible-section button {
  width: 100%;
}

.collapsible-section .content {
  padding: 10px;
}
