.module-container {
    border: 1px solid black;
    border-radius: 19px;
    padding: 15px;
    color: #233067 !important;
    background-color: white;
    margin: 10px;
    font-weight: 500;
    white-space: normal;
    cursor: pointer;
    min-width: 350px;
    max-width: 350px;
    min-height: 136px !important;
}

.module-container:hover {
    border: 1px solid white;
    /* margin-top: -8px !important;
    margin-bottom: 10px !important; */
    -webkit-box-shadow: 17px 15px 18px -4px #233067;
    -moz-box-shadow: 17px 15px 18px -4px #233067;
    box-shadow: 17px 15px 18px -4px #233067;
    color: white !important;
    background-color: #233067;
}

@media only screen and (max-width : 767px)
{
    .mobile-25
    {
        width: 25%;
    }

    .mobile-75 
    {
        width: 75%;
    }
}
