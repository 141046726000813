/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999999;
  
}
 
.box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: auto;
  max-height: 85vh;
  margin-top: calc(100vh - 85vh - 25px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 5px solid #dae634;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 150px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}


.modal-box {
  position: fixed;
  background: #f0f1f3;
  color: rgb(24, 19, 19);
  font-family: sans-serif;
  width: 75%;
  height: auto;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999; /* Higher than popup-box */
  padding: 10px;
  border: 2px solid #fdfbfb;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999998; /* Slightly lower than modal-box */
}

.similarity-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.similarity-table caption {
  font-weight: bold;
  font-size: large;
  font-family: sans-serif;
  color: black;
  margin-bottom: 5px;
  text-align: center;
  caption-side: top; 
}


.similarity-table th, .similarity-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.similarity-table th {
  background-color: #233067;
  color: #f2f2f2;
}

.similarity-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.similarity-table tr:hover {
  background-color: #233067;
  color:#f9f9f9;
}

.modal-box td {
  font-size: 14px;
  line-height: 1.5;
}

.modal-box button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-box button:hover {
  background-color: #03ce03;
}

.text-center {
  text-align: center;
}










                        /* mbp */

/* Popup style */
/* .popup-box { */
/* position: fixed; */
/* background: rgba(0, 0, 0, 0.5); More readable way to specify semi-transparency */
/* width: 100%;
height: 100vh;
top: 0;
left: 0;
z-index: 9999999;
} */

/* .box { */
/* position: absolute; Change from relative to absolute to ensure fixed positioning */
/* width: 90%;
left: 50%;
top: 50%;
transform: translate(-50%, -50%); Center the box */
/* height: auto;
max-height: 85vh;
background: #fff;
border-radius: 4px;
padding: 20px;
border: 5px solid #dae634;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: auto;
}

.close-icon {
cursor: pointer; */
/* position: absolute; Change from fixed to absolute */
/* right: 10px; Position relative to the .box */
/* top: 10px;
background: #ededed;
width: 25px;
height: 25px;
border-radius: 50%; */
/* line-height: 25px; Center the 'x' vertically */
/* text-align: center;
border: 1px solid #999;
font-size: 20px;
} */
