@import url('https://fonts.googleapis.com/css?family=Lato|Montserrat|Open+Sans|Roboto+Condensed|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  font-family: 'Roboto', sans-serif !important;
  letter-spacing: 0.5px !important;
  max-height: 100%;
  -webkit-touch-callout: none;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
  /* border-radius: 50% !important; */
}

::-webkit-scrollbar-button {
  background: #fff !important;
}

::-webkit-scrollbar-track-piece {
  background: #fff !important;
}

::-webkit-scrollbar-thumb {
  background: #737373 !important;
  border-radius: 0.5rem !important;
}

.bg {
  /* background-image: url(./assets/Audire_Login.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* object-fit: cover; */
  /* float: left; */
  min-width: 50%;
  width: 50%;
  min-height: 100%;
}

.iFrame {
  height: 800px !important;
  width: calc(100%) !important;
}

/* .nav-icon {
  color: white !important;
} */
 

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
b {
  letter-spacing: 0.5px !important;
  /* color: #4a5568; */
  font-weight: 500;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

.username {
  color: #e2e8f0 !important;
}

.span {
  padding: 6px;
  /* width: 50%; */
  align-items: center;
  border-radius: 20px;
}

.success {
  border: 1px solid green;
}

.batch {
  padding: 7px;
  margin: 5px;
  margin-top: unset;
  border-radius: 20px;
  letter-spacing: 0.5px !important;
}

.danger {
  border: 1px solid #721c24;
}

.info {
  border: 1px solid #0c5460;
}

.ball {
  /* width: 40px; */
  border-radius: 15px;
  text-align: center;
  margin: 5px;
  /* padding : 5px; */
}


.header-class {
  position: static;
  width: 100%;
}

.table td,
.table th {
  padding: 5px;
  text-align: center;
  height: 15px;
}

.table th {
  white-space: nowrap;
}

option {
  border-radius: 5px !important;
}

.audire-header {
  letter-spacing: .1em;
  /* font-family: Georgia, Cambria, "Times New Roman", Times, serif; */
  font-size: 1.875rem;
  color: #233067;
  font-weight: 900;
}

select:disabled,
input:disabled {
  background-color: lightgrey !important;
}

body {
  min-width: 100px;
  min-height: 100%;
  /* font-family: 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 15px;
}

.card {
  width: 103% !important;
}

.card-div {
  margin-left: -40px !important;
  margin-top: -30px !important;
}


.loader {
  border: 16px solid red;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* .username {
} */

.logout {
  cursor: pointer;
}

.text-white {
  color: #cbd5e0 !important;
}

.text-navy {
  color: #233067 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.text-gray {
  color: #4a5568 !important;
}

.text-lightgray {
  color: #718096 !important;
  font-weight: 500 !important;
}

.text-black {
  color: #000 !important;
  font-weight: 500 !important;
}

.logout:hover {
  height: 80px !important;
  /* width: 50px !important; */
  border: 0px solid;
  border-radius: 30%;
  background-color: #03487c;
}

.table-input,
.table-input:disabled,
table>tbody>td>input,
table>tbody>td>select {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  width: 100% !important;
}

table>tbody>tr.selected {
  border-bottom: 3px solid #f00;
}

.recommendation-holder {
  border: 1px dashed black;
  background-color: lightgray;
  font-weight: bolder;
  padding: 5px;
  margin: 5px;
}

.recommendation {
  border: 1px solid black;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  background-color: lightcyan;
  cursor: pointer;
}

.table {
  white-space: nowrap;
}

.table>tbody>tr>td {
  width: 150px !important;
  overflow: hidden !important;
}

table.tds-recon-table {
  font-size: 18px !important;
}

.number-format {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: unset !important;
  padding-left: 3 !important;
  text-align: right !important;
  font-size: 20px !important;
  /* font-weight: bold !important; */
}

div.sc-jIZahH.gdHjnA.rdt_TableHead {
  background-color: rebeccapurple !important;
}

.recommendation:hover {
  box-shadow: 8px 5px 25px 2px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 8px 5px 25px 2px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 8px 5px 25px 2px rgba(0, 0, 0, 0.57);
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
  margin-top: 40px;
}

.hr-sect:before,
.hr-sect:after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}


.recom-selected {
  background-color: lightgreen;
}

.modal-70w {
  min-width: 77vw !important;
}

table>tbody>tr>td>input[type="text"],
table>tbody>tr>td>select {
  border: 1px solid #233067 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  width: 100% !important;
}

table>tbody>tr>td>input[type="checkbox"] {
  height: unset !important;
  text-align: center !important;
  margin-left: 15px !important;
}

.scroll-table {
  max-height: 400px;
  max-width: 98%;
  overflow: scroll;
}

.audire-sidebar-header {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12' height='12'%3E%3Cpath fill='%239C92AC' fill-opacity='0.045' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E") !important;
  background-color: transparent !important;
  color: #edf2f7 !important;
  letter-spacing: .05em !important;
  font-weight: 900 !important;
  font-size: 1.5rem !important;
  margin-top: -40px;
  text-align: center !important;
  font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.modal-dialog {
  margin-top: 80px;
}

.main-content {
  padding-top: 80px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 235px;
  height: 100%;
  position: relative;
  overflow: auto;
}

.application-loader {
  position: absolute;
  top: 0;
  left: 0;
  padding: 75px 0 0 230px;
  width: 100%;
  height: 100%;
}

.main-content-free {
  /* margin-top: -30px; */
  padding: 10px;
  /* margin-right: 10px; */
  /* margin-left: 230px; */
  min-height: 85vh;
}

.tree-container {
  /* border: 1px solid grey; */
  /* height: max-content !important; */
  /* min-height: 90vh; */
  /* overflow: auto; */
  padding: 10px;
  margin-top: 10px;
}

.page-header {
  border-bottom-width: 2px;
  padding: 5px;
  width: 70%;
  border-color: #233067;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.feild-header {
  /* border-bottom-width: 4px; */
  padding: 5px;
  margin-left: 5px;
  width: 95%;
  border-color: #cbd5e0;
  /* border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem; */
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.App {
  text-align: center;
  /* font-family: Georgia, Cambria, "Times New Roman", Times, serif; */
  /* font-family: 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  letter-spacing: .1em;
  width: 98% !important;
}

.customRowClass {
  max-height: 50px !important;
  height: 20px !important;
  padding-bottom: -5px !important;
  white-space: normal;
}

.customHeaderClass {
  vertical-align: middle !important;
}

.card-header {
  /* height: 50%; */
  background-color: rgb(35, 48, 103);
  color: #edf2f7 !important;
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.menu-bar {
  overflow-x: hidden;
  font-weight: 400;
  line-height: 20px !important;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.menu-bar::-webkit-scrollbar {
  width: 0px !important;
  /* Remove scrollbar space */
  background: transparent !important;
  /* Optional: just make scrollbar invisible */
}

/* .white-border {
  background-color: white !important;
} */

p {
  margin-bottom: 0;
}

.sub-menu-bar {
  margin-left: 10px !important;
}

.MuiTypography-body1 {
  font-size: 13px !important;
  line-height: 15px !important;
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid white;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12' height='12'%3E%3Cpath fill='%239C92AC' fill-opacity='0.045' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
}

.makeStyles-toolbar-5 {
  min-height: 43px !important;
}

.react-date-picker__inputGroup__input {
  border: 0px !important;
}

.react-date-picker__inputGroup__input:active {
  border: 0px !important;
  border-radius: 0px !important;
}

.bottom-menu-button {
  background-color: white;
  color: #000;
}

.sidebar-header-holder {
  /* background-color : white; */
  position: fixed;
  right: 0;
  /* height : 75px; */
  z-index: 999;
}

.sidebar {
  width: 100px;
}

.selectable-row {
  cursor: pointer !important;
}

.selectable-row:hover>td {
  border: 1px solid white;
  margin-top: -2px !important;
  margin-bottom: 3px !important;
  -webkit-box-shadow: 17px 15px 18px -4px #233067;
  -moz-box-shadow: 17px 15px 18px -4px #233067;
  box-shadow: 17px 15px 18px -4px #233067;
  color: #fff !important;
  background-color: #233067;
}

.Chatbot {
  width: 1000px !important;
  background-color: red !important;
}

.rcw-widget-container {
  width: 87vw !important;
  max-width: 87vw !important;
  /* max-height: 200px; */
}

.rcw-sender {
  display: none !important;
}

.react-date-picker__wrapper {
  width: 108% !important;
  border-radius: 25px !important;
  padding: 1px !important;
  margin-left: -15px !important;
  font-size: 15px !important;
}

.rcw-conversation-container.active {
  width: 87vw !important;
  margin-right: 500px !important;
  height: 400px;
}

.rcw-conversation-container>.rcw-header {
  background-color: #233067 !important;
  height: 50px !important;
  padding: 0px !important;
  /* padding: 3px !important; */
  width: 87vw !important;
}


.selection-button {
  background-color: transparent !important;
  border: 1px dashed #233067 !important;
  color: #233067 !important;
  font-weight: 500;
  height: 50px !important;
}

.selection-icon {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.logo-container {
  width: 223px;
  padding: 2px;
}

.submenu {
  position: absolute;
  z-index: 99999;
  left: 175px;
  top: 305px;
  color: black;
  white-space: nowrap;
  background-color: white;
  width: 275px;
  max-height: 333px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid;
}

.profile-pic {
  object-fit: cover;
  width: 100%;
  height: 90px;
}

.ruler {
  height: 3px;
  margin: 5px;
  margin-left: 0px;
  width: 100%;
  background-color: white;
}

.menubar {
  width: 14rem !important;
  background-color: #233067;
  height: 100vh;
  color: white;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.alert-error {
  color: #b30000;
  background-color: #ffcccc;
  border: 1px solid #b30000;
  ;
}

.alert-box {
  z-index: 99999999999999999999;
  position: fixed;
  right: 15px;
  top: 10px;
}

.alert-box-with-app-bar {
  z-index: 99999999999999999999;
  position: fixed;
  right: 15px;
  top: 80px;
}

.card-body,
.card-body p {
  border-color: #cbd5e0 !important;
  border-bottom-width: 1px !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  box-sizing: inherit !important;
  -webkit-tap-highlight-color: transparent !important;
  border-width: 0 !important;
  border-style: solid !important;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  background: #fafafa !important;
  color: #233067 !important;
}

.login-textbox {
  padding: 7px !important;
  border-bottom: 1px solid black !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  outline: none !important;
  border-radius: 0 !important;
}

.login-textbox:focus {
  border: 0 !important;
  outline: none !important;
  border-bottom: 1px solid black !important;
}

input {
  background-color: white !important;
}

.table-holder {
  max-height: 75vh !important;
  overflow: auto;
}

i.icon-with-title {
  border-bottom: 1px dashed;
  text-decoration: none
}

i.icon-with-title:hover {
  cursor: help;
  position: relative
}

i.icon-with-title span {
  display: none
}

i.icon-with-title:hover span {
  font-weight: 600;
  font-size: large;
  border: #c0c0c0 1px solid;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 100;
  background: #f0f0f0 no-repeat 100% 5%;
  left: 0px;
  margin: 10px;
  width: 250px;
  position: absolute;
  bottom: auto;
  top: auto;
  text-decoration: none
}

input:not(.MuiInputBase-input),
select:not(.MuiInputBase-input) {
  border: 1px solid darkgray;
  height: 2rem !important;
  border-radius: 2px !important;
  /* padding: 5px; */
  outline: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}

input[type=radio] {
  height: inherit !important;
}

select[multiple] {
  height: auto !important;
}

/* .css-2613qy-menu */
#react-select-2-listbox {
  z-index: 10 !important;
}

.scroll-body {
  height: 680px;
  overflow: auto;
}

input#date-picker-inline {
  border: 0 !important;
  height: 1.1876em !important;
  border-radius: unset !important;
  padding: 6px 0 7px;
  outline: unset !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;

}

.pagination-select {
  border: unset !important;
  height: unset !important;
  border-radius: unset !important;
  /* padding: 10px !important; */
  outline: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;

}

/* button {
  border-radius: 2.25rem !important;
} */

.form-control {
  padding-bottom: 3px !important;
  padding-left: 12px !important;
  padding-top: 0 !important;
}

input:not(.MuiInputBase-input):focus,
select:not(.MuiInputBase-input):focus {
  border: 2px solid #233067;
  outline: none !important;
}

.error {
  border-bottom: 1px solid red;
}

.warning {
  border: 1px solid #856404;
}

.alert {
  position: fixed;
  top: 13px !important;
  right: 10px !important;
  z-index: 99999;
}

.icon {
  margin-top: 25px;
  border-bottom: 1px solid black;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  max-height: 20px !important;
  max-width: 30px !important;
}

.btn {
  min-height: 2rem !important;
  height: auto !important;
}

.btn-purple {
  height: auto !important;
  background-color: #233067 !important;
  color: white !important;
  font-size: 14px;
  font-weight: 600;
  outline: 0 !important;
}


.wrapper {
  display: flex;
  flex-direction: column;
}

.bar-wrapper {
  margin-top: 5px;
  margin-left: 5px;
}

legend {
  width: auto !important;
}

/* .tree-wrapper {
  height: calc(100% - 25px);
} */

.previous {
  margin-left: 10px;
}

.next,
.collapse {
  margin-right: 10px;
  margin-left: 5px;
}

#app,
.app {
  height: 1000px;
}

input[id^=react-select-] {
  margin-top: -5px !important;
  margin-left: -10px !important;
}

.tree {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #21252B;
}

.m-node.placeholder {
  border: 1px dashed #1385e5;
}

.m-node .inner {
  color: #9DA5B4;
  font-size: 12px;
  font-family: Menlo;
}

.m-node .node {
  display: inline-block;
  width: 100%;
  padding: 8px 8px;
  font-size: 17px;
}

.m-node .node.is-active {
  background-color: #31363F;
}

.group {
  border: 1px solid #000;
  padding: 20px;
  margin: 10px;
  width: 50%;
}

.icon {
  padding: 10px;
}

.inspector {
  padding: 20px;
  border: 1px solid #ccc;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  /* background: #ccc; */
  padding: 10px;
  text-decoration: underline;
}


.tree-buttons {
  border: 0px;
  background: transparent;
  font-size: 12px;
  padding: 3px 2px;
}

.rst__rowTitle {
  font-weight: normal !important;
  font-size: 12px;
}

.rst__rowWrapper {
  padding: 10px 10px 0px 0 !important;
  height: 100%;
  box-sizing: border-box;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: #ccc !important;
}

.tree-wrapper {
  height: calc(100% - 25px);
}

.rst__collapseButton,
.rst__expandButton {
  appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #ccc !important;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.radio-button-label {
  margin-right: 10px;
}

.form-container,
.table-container,
.lable-container {
  border: 1px dashed #ccc;
  padding: 10px !important;
  margin-bottom: 15px;
}

.division-title {
  /* padding: 5px 0px; */
  /* text-transform: uppercase; */
  text-align: CENTER;
  font-size: 18px;
  font-weight: bold;

}

.refresh-button {
  position: absolute !important;
  right: 30px !important;
}

.update-button {
  position: absolute !important;
  right: 60px !important;
}

.process-card {
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.next,
.collapse {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.card-header {
  padding: 10px !important;
}

.card-header button {
  width: 100%;
  background-color: #233067;
  color: white;
  border: 0px;
}

.table-container {
  overflow: overlay !important;
}

.rowViewIcon {
  transform: rotate(90deg) !important;
}

.btn-teal {
  background-color: #233067 !important;
  border-radius: 2.25rem;
  color: white !important;
  font-size: 14px;
  font-weight: 600;
  outline: 0 !important;
}

/* input:focus {
  border-bottom: 1px solid red;
  border-left: 0;
  border-right: 0;
  border-top: 0;
} */

*:focus:not(.MuiDataGrid-cell) {
  outline: none !important;
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media only screen and (max-width : 599px) {

  .main-content {
    margin-left: 30px;
  }
}


@media only screen and (max-width : 800px) {
  .rcw-conversation-container.active {
    width: 87vw !important;
    margin-right: 500px !important;
    height: 100vh;
  }

}

@media only screen and (max-width : 767px) {

  .card {
    width: 95% !important;
    margin-left: 24px;
  }


}

.App-header {
  background-color: #233067;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.field-container {
  padding: 15px
}


input.react-select-2-input,
input:focus.react-select-2-input,
div.css-1pndypt-Input {
  border: 0px !important;
  min-width: 0px !important;
  margin-left: -10px !important;
  margin-top: -5px !important;
  grid-area: unset !important;
}

.css-1s2u09g-control,
.css-1s2u09g-control>div,
.css-1s2u09g-control>div>div {
  min-height: unset !important;
  max-height: 2rem !important;
  padding: 0px !important;
  padding-left: 3px !important;
}

.css-1s2u09g-control {
  border: 1px solid darkgray !important;
  border-radius: 0px !important;
}

.VrGcV div {
  overflow: unset !important;
}

/* .gdHjnA{
  z-index: 0 !important;
  background-color: red !important;
} */