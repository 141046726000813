#table-css {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
 #table-css th {
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 1rem;
  }
  #table-css td {
    border: 1px solid #ddd;
    /* padding: 5px; */
    font-size: .85rem;
    text-align: center;
  }
  
  /* #table-css tr:nth-child(even){background-color: #f2f2f2;} */
  
  /* #table-css tr:hover {background-color: #ddd;} */
  
  #table-css th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    background-color: #e9eaf0;
    color: black;
    text-align: center;
    z-index: 1 !important;
  }

  /* #table-css {
    position: absolute;
    z-index: -1;
  } */