.tile-holder {
    background-color: #c5cae9;
    border: 1px solid #233067;
    border-radius: 15px;
    /* padding: 20px; */
    margin: 5px;
    max-width: 350px !important;
    /* margin-left: 10px; */
}

.tile-holder-yellow {
    background-color: #c5cae9;
    border: 1px solid #233067;
    border-radius: 15px;
    /* padding: 20px; */
    margin: 5px;
    max-width: 350px !important;
}

@media only screen and (max-width : 1200px) {

    .tile-holder {
        margin: 10px;
        max-width: unset !important;
    }

    .tile-holder-yellow {
        margin: 10px;
        max-width: unset !important;
    }
}

.progress-bar {
    cursor: pointer;
}

.progress-bar:hover {
    position: unset !important;
    transform: scale(1.25);
}