.popup {
    font-family: "Montserrat";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 9999;
}

.popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border: 0;
    border-radius: 0 0 8px 8px;
}
  