.flex-screen {
    /* display: flex; */
    background-color: white;
    margin: auto;
    margin-top: 5px;
    width: 80%;
    height: fit-content !important;
    position: absolute;
    padding: 15px;
    padding-top: 55px;
    top: 0;
    bottom: 0;
    right: 0;
    /* max-height: 90vh !important; */
    /* overflow: scroll; */
    /* border: 3px solid #73AD21; */
    /* margin-top: 315px; */
    /* padding-bottom: 30px; */
    text-align: center;
}

@media only screen and (max-width : 720px) {    
    .login-sub1 {
        display: none;    
    }   
}

.login-padding {
    padding: 5rem;
}

@media only screen and (max-width : 1242px) {    
    .login-padding {
        padding: 3rem;    
    }   
}
@media only screen and (max-width : 1000px) {    
    .login-padding {
        padding: 1rem;    
    }   
}

@media only screen and (max-width : 1000px) {    
    .login-padding {
        padding: 1rem;    
    }   
}

.grey-text {
    color: #718096;
    text-align: center;
}

.ui_link {
    color: #4a5568;
    cursor: pointer;
}

.ui_link :hover {
    color: black;
    text-decoration: underline;
    background-color: transparent;
}

.div-container {
    border: 1px solid;
}


.org-container {
    padding: 0px;
    cursor: pointer;
    margin: 19px;
    align-self: baseline;
}


.org-container:hover {
    margin-top: 3px;
    color: white;
    -webkit-box-shadow: 11px 13px 26px 8px rgba(35, 48, 102, 0.77);
    -moz-box-shadow: 11px 13px 26px 8px rgba(35, 48, 102, 0.77);
    box-shadow: 11px 13px 26px 8px rgba(35, 48, 102, 0.77);
}

.logo-box {
    /* border: 1px solid #233067; */
    width: 100%;
}

@media only screen and (max-width : 767px)
{
    .org-container{
        height: 50px;
        width: 78px;
    }
}