body,
html,
#root,
.wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
}

/* * {
    user-select: none !important;
} */

.wrapper {
    display: flex;
    flex-direction: column;
}

.bar-wrapper {
    margin-top: 5px;
    margin-left: 5px;
}

legend {
    width: auto !important;
}

/* .tree-wrapper {
  height: calc(100% - 25px);
} */

.previous {
    margin-left: 10px;
}

.next,
.collapse {
    margin-right: 10px;
    margin-left: 5px;
}

#app,
.app {
    height: 1000px;
}

.tree {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #21252B;
}

.m-node.placeholder {
    border: 1px dashed #1385e5;
}

.m-node .inner {
    color: #9DA5B4;
    font-size: 12px;
    font-family: Menlo;
}

.m-node .node {
    display: inline-block;
    width: 100%;
    padding: 8px 8px;
    font-size: 17px;
}

.m-node .node.is-active {
    background-color: #31363F;
}

.group {
    border: 1px solid #000;
    padding: 20px;
    margin: 10px;
    width: 50%;
}

.icon {
    padding: 10px;
}

.inspector {
    padding: 20px;
    border: 1px solid #ccc;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    /* background: #ccc; */
    padding: 10px;
    text-decoration: underline;
}

.field-container {
    padding: 15px
}

.group-container {
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.tree-buttons {
    border: 0px;
    background: transparent;
    font-size: 12px;
    padding: 3px 2px;
}

.rst__rowTitle {
    font-weight: normal !important;
    font-size: 12px;
}

.rst__rowWrapper {
    padding: 10px 10px 0px 0 !important;
    height: 100%;
    box-sizing: border-box;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    position: absolute;
    content: '';
    background-color: #ccc !important;
}

.rst__collapseButton,
.rst__expandButton {
    appearance: none;
    border: none;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #ccc !important;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.radio-button-label {
    margin-right: 10px;
}

.form-container,
.table-container {
    border: 1px dashed #ccc;
    padding: 10px !important;
    margin-bottom: 15px;
}

.division-title {
    padding: 5px 0px;
    text-transform: uppercase;
    text-align: CENTER;
    font-size: 18px;
    font-weight: bold;

}

.next,
.collapse {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.card-header {
    padding: 0px !important;
}

.card-header button {
    width: 100%;
    background-color: #e4e4e4;
    border: 0px;
}

.table-container {
    overflow: overlay !important;
}

.rowViewIcon {
    transform: rotate(90deg) !important;
}