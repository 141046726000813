.header_div {
  align-items: center;
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: sans-serif;
}

.header_div_selection_screen {
align-items: center;
text-align: center;
font-size: x-large;
font-weight: bolder;
font-family: sans-serif;
}

.detail {
  margin-right: 5px;
}

.highlight {
  background-color: #a0d3ff; /* Light highlight color */
}

.detail label {
  margin-right: 10px;
}

.preview-table {
  border: #233067 2px dashed;
  padding: 10px;
  margin: 10px;
  background-color: lightblue;
  transition: 2s;
}

.display-table {
  color: #233067;
}

.spacing {
  width: 100% !important;
  text-align: left;
  height: 150px;
  margin-bottom: 5px;
      margin-top: 3px;
      font-family: cursive, sans-serif;
      outline: 0;
      /* background: #2ecc71; */
      /* color: #fff; */
      /* border: 1px solid crimson; */
      padding: 4px;
      border-radius: 9px;
}

.spacing1{
  width: 80% !important;
  text-align: left;
}

.center-screen {
display: flex;
justify-content: center;
align-items: center;
text-align: center;
min-height: 60vh;
}

#table_styling{
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  overflow: scroll;
  border-collapse: collapse;
}

#table_styling td, #table_styling th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table_styling tr:nth-child(even){background-color: #f2f2f2;}

#table_styling tr:hover {background-color: #ddd;}

#table_styling th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;
background-color: #233067;
color: white;
}

.wrapper{
  align-items: center;
}

.buttonConfirm {
  position: absolute;
  top: 50%;
}

.table-wrapper{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

bodY {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, #00BAF7, #1E46A3);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* body{
  background:#eee;
} */
.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #d2d6de;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
  border: 1px solid #d2d6de;
  border-radius: 0;
  padding: 6px 12px;
  height: 34px
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 10px
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  margin-top: -3px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px
}

.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
  border: 1px solid #d2d6de;
  border-radius: 0 !important;
  padding: 6px 12px;
  height: 40px !important
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 6px !important;
  right: 1px;
  width: 20px
}

.red-dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -10px;
  right: -10px;
}