.wrapper-table1{
    display: flex;
    flex-direction: column;
}

.wrapper-table2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    overflow-x: auto;
}

@media (min-width: 640px) {
    .sm\:-mx-6 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}

@media (min-width: 1024px) {
    .lg\:-mx-8 {
        margin-left: -2rem/* -32px */;
        margin-right: -2rem/* -32px */;
    }
}

.wrapper-table3{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    vertical-align: middle;
    display: inline-block;
    min-width: 100%;   
}

.wrapper-table4{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    overflow: hidden;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

@media (min-width: 640px) {
    .sm\:rounded-lg {
        border-radius: 0.5rem/* 8px */;
    }
}

.new-table{
    min-width: 100%;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.new-table-header{
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

#new-table th{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: left;
    font-size: 0.75rem/* 12px */;
    line-height: 1rem/* 16px */;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.new-table-body{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#new-table td{
    padding-left: 1.5rem/* 24px */;
    padding-right: 1.5rem/* 24px */;
    padding-top: 1rem/* 16px */;
    padding-bottom: 1rem/* 16px */;
    white-space: nowrap;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.div-container1{
    width:18rem;
    font-weight: 500;
    /* height: 20rem; */
}

.div-container2{
    /* --tw-bg-opacity: 1; */
    /* background-color: rgb(255 255 255 / var(--tw-bg-opacity)); */
    background-color: white;
    width:100%;
    padding:0.5rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-radius:0.25rem;
    border-style: groove;
    cursor: pointer;
}

.div-container22{
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bichevron-rotate{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ul-container{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    margin-top: 0.5rem;
    overflow-y: auto;
    width: 100%;
    /* position: absolute; */
    /* border-style: ridge; */
}

.ul-maxheight{

    max-height: 15rem;
}

.ul-minheight{
    max-height: 0px;
}

.div-container3{
    display: flex;
    align-items: center;
    /* padding-left: 0.5rem;
    padding-right: 0.5rem; */
    position: sticky;
    top: 0px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.aioutlinesearch{
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.placeholder-input{
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.select-input{
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 0.5rem;
}

.li-container{
    padding:0.5rem;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    --tw-bg-opacity: 1;
    /* background-color: rgb(2 132 199 / var(--tw-bg-opacity)); */
    background-color: whitesmoke;
    --tw-text-opacity: 1;
    /* color: rgb(255 255 255 / var(--tw-text-opacity)); */
    color: black;
    cursor: pointer;
    /* position: absolute; */
}

.li-container-special{
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.li-block{
    display:block;
}

.li-hidden{
    display: none;
}
